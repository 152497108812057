import React, { useState } from "react";

export default function Footer() {
    const [date] = useState(new Date());
    return (
        <footer style={{ textAlign: "center", marginTop: 50, color: "gray" }}>
            <span>
                Copyright © {date.getFullYear()}{" "}
                <a
                    href="https://www.stoegercanada.ca/"
                    style={{
                        color: "gray",
                    }}
                >
                    Stoeger Canada
                </a>
            </span>
        </footer>
    );
}
