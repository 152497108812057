import React from "react";
// import { useTheme } from "@emotion/react";

const LogoGroup = () => {
    // const theme = useTheme();
    return (
        // <div
        //     align="center"
        //     style={{
        //         margin: theme.spacing(2, "auto"),
        //         display: "flex",
        //         justifyContent: "center",
        //         alignItems: "center",
        //     }}
        // >
        //     <span>
        //         <img src="/images/tikka_horizontal_black.png" alt="Tikka" style={{ width: "200px" }} />
        //     </span>
        //     <span>
        //         <img
        //             src="/images/Burris_Logo_Icon_RedBlackWhite_CMYKb.png"
        //             alt="Burris"
        //             style={{ width: "200px", margin: "20px 30px 20px" }}
        //         />
        //     </span>
        //     <span>
        //         <img src="/images/crps.png" alt="canadian rimfire percision series" style={{ width: "200px" }} />
        //     </span>
        //     <span>
        //         <img
        //             src="/images/Silvercore_Badge_Dark.png"
        //             style={{
        //                 width: "100px",
        //                 margin: "20px 30px 20px",
        //             }}
        //             alt="silvercore"
        //         />
        //     </span>
        // </div>
        <div>
            <img
                src="/images/logo_footer.png"
                alt="Sponsors: Tikka, Burris, CRPS, Silvercore Outdoors"
                style={{ width: "100%" }}
            />
        </div>
    );
};

export default LogoGroup;
