import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { useNavigate } from "react-router";

export default function Header() {
    const navigator = useNavigate();
    const handleRoute = () => {
        navigator("/");
    };

    return (
        <div>
            <AppBar
                style={{
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                    // display: "flex",
                }}
                position="static"
            >
                <Toolbar>
                    <img
                        onClick={handleRoute}
                        style={{ width: "16%", display: "block", margin: "20px 10px 20px" }}
                        src="/images/tikka_horizontal_black.png"
                        alt="Beretta"
                    />

                    {/* <img
                        onClick={handleRoute}
                        style={{ width: "12%", display: "block", margin: "20px 10px 20px" }}
                        src="/images/tikka_horizontal_black.png"
                        alt="Beretta"
                    />
                    <img
                        src="/images/Burris_Logo_Icon_RedBlackWhite_CMYKb.png"
                        alt="Burris"
                        style={{ width: "12%", display: "block", margin: "20px 10px 20px" }}
                    /> */}
                </Toolbar>
                {/* <a href=""> */}
                {/* <span>
                    <img
                        onClick={handleRoute}
                        style={{ width: "12%", display: "block", margin: "20px 30px 20px" }}
                        src="/images/tikka_horizontal_black.png"
                        alt="Beretta"
                    />
                </span>
                <span>
                    <img
                        src="/images/Burris_Logo_Icon_RedBlackWhite_CMYKb.png"
                        alt="Burris"
                        style={{ width: "12%", display: "block", margin: "20px 30px 20px" }}
                    />
                </span> */}
                {/* </a> */}
            </AppBar>
        </div>
    );
}
