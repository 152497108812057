import React from "react";
import { Divider, Grid, List, ListItem, Typography } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "@emotion/styled";

// const useStyles = makeStyles((theme) => ({
//     background: {
//         backgroundImage: "url(/images/404.jpg)",
//         backgroundRepeat: "no-repeat",
//         backgroundColor: theme.palette.grey[50],
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//     },
//     message: {
//         margin: "25vh auto",
//         color: "white",
//     },
//     divider: {
//         background: "white",
//         width: "25%",
//         margin: theme.spacing(4, "auto"),
//     },
// }));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    // backgroundImage: "url(/images/404.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
}));
const StyledDiv = styled("div")(({ theme }) => ({
    margin: "25vh auto",
    // color: "white",
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
    // background: "white",
    width: "25%",
    margin: theme.spacing(4, "auto"),
}));

export default function Error404() {
    // const classes = useStyles();

    return (
        <div>
            <Header />
            <StyledGridContainer container spacing={0} direction="column" alignItems="center">
                <StyledDiv>
                    <List>
                        <ListItem>
                            <div>
                                <Typography variant="h4">Error 404: Page Cannot be Found</Typography>
                                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                                    Please verify that the url is correct.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                    {/* <Divider classes={{ root: classes.divider }} /> */}
                    {/* <StyledDivider />
                    <List>
                        <ListItem>
                            <div>
                                <Typography variant="h4">Erreur 404: Page Est Introuvable</Typography>
                                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                                    Veuillez vérifier que l'URL est correctement saisie.
                                </Typography>
                            </div>
                        </ListItem>
                    </List> */}
                </StyledDiv>

                <Footer />
            </StyledGridContainer>
        </div>
    );
}
