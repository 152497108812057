import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

export default function Terms(props) {
    const theme = useTheme();
    const [date] = useState(new Date());
    const handleClose = () => {
        props.close(false);
    };

    return (
        <div>
            <Dialog maxWidth="md" aria-labelledby="customized-dialog-title" open={props.openDialog}>
                <DialogTitle id="customized-dialog-title" align="center">
                    {/* <img
                        // className={classes.logo}
                        style={{ width: "200px" }}
                        src="/images/SteinerLOGO-color-master.jpg"
                        alt="Steiner Logo"
                    /> */}
                    <Typography
                        variant="h5"
                        // className={classes.bold}
                        style={{ fontWeight: "Bold" }}
                    >
                        CRPS Terms and Conditions
                    </Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: "absolute",
                            right: theme.spacing(1),
                            top: theme.spacing(1),
                            color: theme.palette.grey[500],
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    {/* <Typography variant="subtitle1" component={"span"}>
                        CONTEST PERIOD:{" "}
                    </Typography>
                    <Typography variant="subtitle2" component={"span"} sx={{ my: 1 }}>
                        The Shoot to Win Contest ("Contest") is a contest that begins on May 1st and ends on September
                        23rd, 2023 at 10:00 am EST ("Contest Period"). Draw will be held on September 23rd at the CRPS
                        National in Cornwall, Ontario.
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        // className={classes.vertical_spacing}
                        sx={{ my: 1 }}
                    >
                        TERMS & CONDITIONS
                    </Typography> */}
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>CONTEST PERIOD:</strong> The Shoot to Win Contest ("Contest") is a contest that begins
                        on May 1st and ends on September 23rd, 2023 at 10:00 am EST ("Contest Period"). Draw will be
                        held on September 23rd at the CRPS National in Cornwall, Ontario.
                    </Typography>
                    {/* <Typography
                        variant="subtitle1"
                        // className={classes.vertical_spacing}
                        sx={{ my: 1 }}
                    >
                        RELEASE
                    </Typography> */}
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>ELIGIBILITY:</strong> The Contest is open to residents of Canada (excluding residents of
                        Quebec) who have reached the legal age of majority in their province/territory of residence by
                        the beginning of the Contest Period and have a Possession and Acquisition Licence (PAL).
                        Employees of the Sponsor and persons with whom they are domiciled, whether related or not, and
                        employees of the advertising and promotional agencies used in connection with the Contest, are
                        not eligible to enter ("Contest Parties"). By participating, Contest entrants agree to be bound
                        by the Rules and Regulations ("Rules") and interpretation of these Rules by the Sponsor.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>HOW TO ENTER:</strong> NO PURCHASE NECESSARY. To enter the Contest, eligible entrants
                        must participate in any CRPS matches and/or training by Silvercore and submit a completed
                        Official Contest Entry Form ("Entry Form") during the Contest Period ("Entry").
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        An Entry Form must be completed and submitted online or at the event with the CRPS
                        host/volunteer during the Contest Period as follows to be a valid Entry for the Contest: (i)
                        enter your first name, last name, postal code, and valid email address; and (ii) click "Submit".
                        By clicking "Submit", you must sign that you have read and agree to be legally bound by these
                        Rules., The Entry Form must be fully completed with all required information and must be
                        submitted and received in accordance with these Rules during the Contest Period to be eligible.
                        Each eligible Entry Form submitted and received following these Rules during the Contest Period
                        is entered into the Contest.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>SPONSOR:</strong> Stoeger Canada, CRPS and Silvercore are the sponsors of the Contest
                        ("Sponsor").
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        CANADIAN RIMFIRE PRECISION SERIES (CRPS) MATCHES AND RANGE DAYS: Stoeger Canada partners and
                        affiliates will be held harmless and not liable in part or whole by any CRPS Range Days and
                        Activities. CRPS is a separate organization run independently and outside of Stoeger Canada
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        SILVERCORE TRAINING AND SHOOTS: Stoeger Canada partners and affiliates will be held harmless and
                        not liable in part or whole by any Silvercore Training and Activities. Silvercore is a separate
                        organization run independently and outside of Stoeger Canada.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>ENTRY LIMIT:</strong> There is a limit of one Entry per person during the Contest
                        Period, regardless of the method of entry. If it is discovered by the Sponsor, using any
                        evidence or other information made available to or otherwise discovered by the Sponsor, that any
                        person has attempted to or has: (a) submitted more than one Entry; (b) submitted multiple Entry
                        Forms using multiple names, identities, email addresses; and/or (c) used any automated, macro,
                        script, robotic or another system(s) or program(s) to enter or otherwise participate in or to
                        disrupt this Contest, then such person may be disqualified from the Contest in the sole and
                        absolute discretion of the Sponsor. An Entry may be rejected if, in the sole and absolute
                        discretion of the Sponsor, the Entry Form is not fully completed with all required information
                        and submitted and received in accordance with these Rules during the Contest Period. The
                        Released Parties (defined below) will not be responsible for a late, lost, misdirected, delayed,
                        incomplete or incompatible Entry, all of which are void. Facsimiles and/or photocopies of Entry
                        Forms will not be accepted.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>VERIFICATION OF ENTRY:</strong> Each Entry is subject to verification at any time and
                        for any reason. The Sponsor reserves the right, in its sole and absolute discretion, to require
                        proof of identity and/or eligibility, in a form acceptable to the Sponsor, including, without
                        limitation, government-issued photo identification, including PAL: (a) for the purposes of
                        verifying an individual's eligibility to participate in this Contest; (b) for the purposes of
                        verifying the eligibility and/or legitimacy of any Entry entered or purportedly entered, for the
                        purposes of this Contest; and/or (c) for any other reason the Sponsor deems necessary, in its
                        sole and absolute discretion, for the purposes of administering this Contest in accordance with
                        these Rules. Failure to provide such proof to the complete satisfaction of the Sponsor in a
                        timely manner may result in disqualification is the sole and absolute discretion of the Sponsor.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>THE PRIZE AND APPROXIMATE RETAIL VALUE:</strong> The Prize has an approximate value of
                        $2,805 CAD ("Prize"). Under no circumstances will any difference between the actual retail value
                        of the Prize at the time of prize fulfillment and the approximate retail value of the Prize be
                        awarded. Without limiting the generality of the foregoing, the following general conditions
                        apply: (a) the Prize must be accepted as awarded and is not transferable, assignable or
                        convertible to cash except as may be specifically permitted by Sponsor in its sole and absolute
                        discretion; (b) no substitutions will be available, except in the Sponsor's sole and absolute
                        discretion; (c) Sponsor reserves the right at any time to substitute the Prize for any reason
                        with a prize of equal or greater value, including, without limitation, a cash award.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                        TF17558A598B68 TIKKA T1x UPR 22LR 20 NS 10RRD MT1/2X28 – MSRP $1,485
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                        200481 RT-25 5-25X56MM SCR2 MIL FFP – MSRP $1,185
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                        410630 XTB 2-PIECE BASE – MSRP $35
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        420164 XTR 2-PIECE RINGS – MSRP $100
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>WINNER SELECTION PROCESS AND ODDS OF WINNING:</strong> One eligible Entry will be
                        selected by random draw from among all eligible Entries submitted and received in accordance
                        with these Rules during the Contest Period ("Winner"). The odds of being a Winner depend on the
                        number of eligible Entries submitted and received in accordance with these Rules during the
                        Contest Period. In total, there will be one Winner, who will be eligible to win a Prize, subject
                        to the terms and conditions of these Rules.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>WINNER NOTIFICATION PROCESS:</strong> The Sponsor or its designated representative will
                        make a minimum of one attempt to contact each Winner, using the information provided on the
                        Entry Form, within five (5) business days of the Draw Date. If the selected entrant cannot be
                        contacted within 48 hours of the Draw Date, or if there is a return of any notification as
                        undeliverable, then the selected entrant may, in the sole and absolute discretion of the
                        Sponsor, be disqualified and, if disqualified, will forfeit all rights to a Prize. Furthermore,
                        the Sponsor reserves the right, in its sole and absolute discretion and time permitting, to
                        randomly select an alternate Winner from among the remaining eligible Entries submitted and
                        received in accordance with these Rules during the Contest Period, in which case these Rules
                        shall apply to such newly selected Winner.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>WINNER CONFIRMATION PROCESS: </strong>BEFORE BEING DECLARED THE CONFIRMED PRIZE WINNER
                        AND BEFORE RECEIVING THE PRIZE, the selected entrant will be required to: (i) confirm they are a
                        current resident of Canada and have a valid PAL (ii) answer a mathematical skill-testing
                        question without mechanical aid; (iii) acknowledge acceptance of the Prize, as awarded, within
                        48 hours of the Sponsor or its designated representative providing notice of the draw; (iv)
                        execute a release of the Contest Parties and each of their respective officers, directors,
                        agents, representatives, successors and assigns (collectively, "Released Parties") from any and
                        all liability in connection with this Contest, their participation therein and/or the awarding
                        and use/misuse of the Prize or any portion thereof; and (iv) agree to the publication,
                        reproduction and/or other use of their name, voice, statements about the Contest and/or
                        photograph or other likeness without further notice or compensation, in any publicity or
                        advertisement carried out by or on behalf of the Sponsor in any manner whatsoever, including
                        print, broadcast or the internet. If the selected entrant: (a) cannot accept or is unwilling to
                        accept a Prize, as awarded, for any reason; and/or (b) is determined to be in violation of these
                        Rules, all as determined by the Sponsor in its sole and absolute discretion, then he/she may, in
                        the sole and absolute discretion of the Sponsor, be disqualified and, if disqualified, will
                        forfeit all rights to a Prize. Furthermore, the Sponsor reserves the right, in its sole and
                        absolute discretion and time permitting, to randomly select an alternate Winner from among the
                        remaining eligible Entries submitted and received in accordance with these Rules during the
                        Contest Period, in which case these Rules shall apply to such newly selected Winner.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        <strong>General Conditions</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        Every Entry becomes the property of the Sponsor.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        This Contest is subject to all applicable federal, provincial and municipal laws.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        The decisions of the Sponsor with respect to all aspects of this Contest are final and binding
                        on all entrants without the right of appeal, including, without limitation, any decisions
                        regarding the eligibility/disqualification of Entries and/or entrants. ANYONE DETERMINED BY THE
                        SPONSOR TO BE IN VIOLATION OF THESE RULES FOR ANY REASON IS SUBJECT TO DISQUALIFICATION AT THE
                        SOLE AND ABSOLUTE DISCRETION OF THE SPONSOR AT ANY TIME.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        The Released Parties will not be liable for: (i) any failure of the Website during the Contest;
                        (ii) any technical malfunction or other problems relating to the telephone network or lines,
                        online computer systems, servers, access providers, computer equipment or software; (iii) the
                        failure of any Entry or other information to be received, captured or recorded for any reason
                        whatsoever, including, but not limited to, technical problems or traffic congestion on the
                        internet or at any website; (iv) any injury or damage to any person who submits an Entry or any
                        other person, including injury or damage to any computer or other device related to or resulting
                        from participating in the Contest; and/or (v) any combination of the above.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        In the event of a dispute regarding who submitted an Entry, the Sponsor reserves the right, in
                        its sole and absolute discretion, to deem the Entry to have been submitted by the authorized
                        account holder of the email address submitted at the time of entry. "Authorized account holder"
                        is defined as the person who is assigned an email address by an internet provider, online
                        service provider or other organization (e.g. business, educational institute, etc.) that is
                        responsible for assigning email addresses for the domain associated with the submitted email
                        address. An entrant may be required to provide proof, in a form acceptable to the Sponsor,
                        including, without limitation, government-issued photo identification, that he/she is the
                        authorized account holder of the email address associated with the Entry in question.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        The Sponsor reserves the right to withdraw, amend or suspend this Contest or to amend these
                        Rules in any way in the event of an error, technical problem, computer virus, bugs, tampering,
                        unauthorized intervention, fraud, technical failure or any other cause beyond the reasonable
                        control of the Sponsor that interferes with the proper conduct of this Contest as contemplated
                        by these Rules. Any attempt to deliberately damage the Website or to undermine the legitimate
                        operation of this Contest in any way, as determined by the Sponsor in its sole and absolute
                        discretion, is a violation of criminal and civil laws, and should such an attempt be made, the
                        Sponsor reserves the right to seek remedies and damages to the fullest extent permitted by law.
                        The Sponsor reserves the right to cancel, amend or suspend this Contest, or to amend these
                        Rules, in any way without prior notice or obligation, in the event of any accident, printing,
                        administrative or other error of any kind or for any other reason whatsoever.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        By entering this Contest, each person who submits an Entry expressly consents to the Sponsor,
                        its agents and/or representatives, storing, sharing and using the personal information submitted
                        with their Entry only for the purpose of administering the Contest and in accordance with
                        Sponsor's privacy policy posted on its Website. This does not limit any other consent(s) that an
                        individual may provide the Sponsor or others in relation to the collection, use and/or
                        disclosure of their personal information.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        The Sponsor reserves the right, in its discretion and without prior notice, to adjust any of the
                        dates, timeframes and/or other Contest mechanics stipulated in these Rules, to the extent
                        necessary, for purposes of verifying compliance of any Entry with these Rules, or as a result of
                        any technical or other problems, or in light of any other circumstances which, in the opinion of
                        the Sponsor, in its sole and absolute discretion, affect the proper administration of the
                        Contest as contemplated in these Rules, or for any other reason.
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
                        In the event of any discrepancy or inconsistency between the terms and conditions of these Rules
                        and disclosures or other statements contained in any Contest-related materials, including, but
                        not limited to, the Entry Form, Website, and/or point of sale, television, print or online
                        advertising, the terms and conditions of these Rules shall prevail, govern and control to the
                        fullest extent permitted by law.
                    </Typography>

                    {/* <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        // className={classes.logo_div}
                        sx={{ mt: 3 }}
                    >
                        <Grid item md={6} sm={6} xs={6}>
                            <img
                                style={{ width: "50%" }}
                                // className={classes.end_logo1}
                                src="/images/SteinerLOGO-color-master.jpg"
                                alt="Steiner Logo"
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} align="right">
                            <img
                                style={{ width: "65%" }}
                                // className={classes.end_logo2}
                                src="/images/Stoeger_logo.png"
                                alt="Stoeger Logo"
                            />
                        </Grid>
                    </Grid> */}
                    <Typography
                        // className={clsx(classes.vertical_spacing, classes.logo_div)}
                        sx={{ mt: 3, mb: 1 }}
                        align="right"
                        variant="body2"
                        gutterBottom
                    >
                        © {date.getFullYear()} Stoeger Canada(1990) Ltd.
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}
