import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import MuseoSans from "./fonts/MuseoSans_3.otf";

const theme = createTheme({
    palette: {
        primary: {
            main: "#277cbf",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#277cbf",
            contrastText: "#ffffff",
        },
        textField: {
            main: "#ffffff",
            contrastText: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["MuseoSans", "Roboto"],
        // h1: {
        //     fontFamily: "MuseoSans, sans-serif",
        // },
        h2: {
            fontFamily: "MuseoSans, sans-serif",
            //fontSize: "72px",
        },
        h3: {
            fontFamily: "MuseoSans, sans-serif",
        },
        h4: {
            fontFamily: "MuseoSans, sans-serif",
        },
        h5: {
            fontFamily: "MuseoSans, sans-serif",
        },
        h6: {
            fontFamily: "MuseoSans, sans-serif",
        },
        subtitle1: {
            fontFamily: "MuseoSans, sans-serif",
            fontStyle: "italic",
            fontSize: "24px",
        },
        subtitle2: {
            fontFamily: "Libre Baskerville, serif",
            fontStyle: "italic",
            fontSize: "24px",
        },
        caption: {
            fontWeight: 400,
        },
        body1: {
            fontFamily: '"Roboto"',
            fontWeight: 400,
            fontSize: "1.05rem",
            lineHeight: 1.5,
        },
        body2: {
            fontFamily: '"Roboto"',
            fontWeight: 400,
            fontSize: "1.05rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
    },
    components: {
        MuiCssBaseline: {
            // add font-face to MuiCssBaseline component
            styleOverrides: `
        @font-face {
          font-family: 'MuseoSans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MuseoSans'), url(${MuseoSans}) format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        },
    },
});
function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
