import React, { useState } from "react";
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    Link,
    MenuItem,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";
import styled from "@emotion/styled";
import LogoGroup from "../components/LogoGroup";

const StyledGrid = styled(Grid)(({ theme }) => ({
    backgroundImage: "url(/images/background.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
}));
const StyledCard = styled(Card)(({ theme }) => ({
    width: "80%",
    background: "rgba(255, 255, 255, 0.85)",
    margin: theme.spacing(8, "auto", 2),
    padding: theme.spacing(4, 4, 6),
    [theme.breakpoints.only("sm")]: {
        width: "95%",
    },
    [theme.breakpoints.only("xs")]: {
        width: "100%",
    },
}));

const StyledTextFeild = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    "& label.Mui-focused": {
        color: "black",
    },
    "& label": {
        color: "black",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "black",
        },
        "&:hover fieldset": {
            borderColor: "#263238",
        },
        "&.Mui-focused fieldset": {
            borderColor: "black",
        },
    },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    background: "rgba(0, 0, 0, 0.87)",
    width: "25%",
    margin: theme.spacing(3, "auto"),
}));

export default function ThankYou() {
    const theme = useTheme();
    const [newsletterData, setNewsletterData] = useState({
        first: "",
        last: "",
        email: "",
        province: "",
        subscribe: false,
    });
    const [subscribed, setSubscribed] = useState(false);

    const handleSubscribe = (e) => {
        e.preventDefault();

        const tmp = steralizeInput(newsletterData);

        fetch("https://secondtonone.tikkacanada.ca/api/subscribeToStoeger", {
            // fetch("http://localhost:8080/api/subscribeToStoeger", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
            body: JSON.stringify(tmp),
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    setSubscribed(true);
                    // reset form for another user to subscribe
                    setNewsletterData({
                        first: "",
                        last: "",
                        email: "",
                        province: "",
                        subscribe: false,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const steralizeInput = (form) => {
        let tmp = Object.entries(form);
        tmp = tmp.map((m) => (typeof m[1] === "string" ? [m[0], m[1].replace(/^\s+|\s+$/gm, "")] : m));

        return Object.fromEntries(tmp);
    };

    const handleFormData = (e) => {
        setNewsletterData({
            ...newsletterData,
            [e.target.name]: e.target.value,
        });
    };
    const handleChecks = (e) => {
        setNewsletterData({
            ...newsletterData,
            [e.target.name]: e.target.checked,
        });
    };

    // const testing = () => {
    //     // fetch("https://predatordraw.steinercanada.ca/api/subscribeToStoeger", {
    //     fetch("http://localhost:8080/api/testing", {
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         method: "post",
    //     })
    //         .then((res) => {
    //             console.log(res);
    //             return res.json();
    //         })
    //         .then((data) => console.log(data))
    //         .catch((err) => console.log(err));
    // };

    return (
        <div>
            <Header />
            <StyledGrid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "100vh" }}
            >
                <StyledCard>
                    <div sx={{ m: "auto" }} style={{ textAlign: "center" }}>
                        <Typography variant="h3">THANK YOU FOR ENTERING!</Typography>
                    </div>

                    <div align="center" sx={{ mt: 4, mx: "auto" }}>
                        <div sx={{ mt: 2, mx: "auto" }}>
                            <Button
                                sx={{ mt: 2, mb: 2, mx: "auto" }}
                                component="a"
                                href="http://www.tikkacanada.ca"
                                // style={{ textDecoration: "underline" }}
                                variant="contained"
                            >
                                Return to Tikka Canada
                            </Button>
                        </div>
                        <div align="center">
                            <Link href="https://rimfireprecision.ca/" color="secondary" style={{ fontSize: "16px" }}>
                                <Typography variant="body1">
                                    Click Here to visit the Canadian Rimfire Precision Series (CRPS) website.
                                </Typography>
                            </Link>
                        </div>
                        <div align="center">
                            <Link href="https://silvercore.ca/" color="secondary" style={{ fontSize: "16px" }}>
                                <Typography variant="body1">Click Here to visit Silvercore's website.</Typography>
                            </Link>
                        </div>
                    </div>

                    <StyledDivider />
                    <form onSubmit={handleSubscribe} sx={{ px: 3 }}>
                        <div sx={{ m: "auto" }} style={{ textAlign: "center" }}>
                            <Typography variant="h5" gutterBottom>
                                Don't forget to sign up for our newsletter!
                            </Typography>
                        </div>
                        <Typography align="center" variant="body1">
                            Make sure to join our Newsletter for online sales, contests, draws and new product
                            information.
                        </Typography>

                        <div sx={{ mt: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <StyledTextFeild
                                        variant="outlined"
                                        label="First Name / Prénom"
                                        fullWidth
                                        required={true}
                                        name="first"
                                        value={newsletterData.first}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <StyledTextFeild
                                        variant="outlined"
                                        label="Last Name / Nom de famille"
                                        fullWidth
                                        required={true}
                                        name="last"
                                        value={newsletterData.last}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <StyledTextFeild
                                        required={true}
                                        fullWidth
                                        name="email"
                                        value={newsletterData.email}
                                        onChange={handleFormData}
                                        variant="outlined"
                                        label="Email / Courriel"
                                        type="email"
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <StyledTextFeild
                                        variant="outlined"
                                        fullWidth
                                        label="Province / Province"
                                        name="province"
                                        value={newsletterData.province}
                                        required={true}
                                        onChange={handleFormData}
                                        select
                                    >
                                        <MenuItem value="AB">Alberta</MenuItem>
                                        <MenuItem value="BC">British Columbia</MenuItem>
                                        <MenuItem value="MB">Manitoba</MenuItem>
                                        <MenuItem value="NB">New Brunswick</MenuItem>
                                        <MenuItem value="NL">Newfoundland and Labrador</MenuItem>
                                        <MenuItem value="NT">Northwest Territories</MenuItem>
                                        <MenuItem value="NS">Nova Scotia</MenuItem>
                                        <MenuItem value="NU">Nunavut</MenuItem>
                                        <MenuItem value="ON">Ontario</MenuItem>
                                        <MenuItem value="PE">Prince Edward Island</MenuItem>
                                        <MenuItem value="QC">Quebec</MenuItem>
                                        <MenuItem value="SK">Saskatchewan</MenuItem>
                                        <MenuItem value="YT">Yukon</MenuItem>
                                    </StyledTextFeild>
                                </Grid>
                            </Grid>
                            <div sx={{ my: 2 }}>
                                <FormControl>
                                    <Typography variant="body2" style={{ display: "inline" }}>
                                        <Checkbox
                                            required
                                            checked={newsletterData.subscribe}
                                            size="small"
                                            onChange={handleChecks}
                                            name="subscribe"
                                            color="secondary"
                                            style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                        />
                                        *{" "}
                                        <strong>
                                            Please subscribe me to Stoeger Canada, the Canadian distributor for Tikka
                                            and Tikka's Newsletter.
                                        </strong>{" "}
                                        By checking this box, I opt-in to receive periodic information on promotions,
                                        sales, events and new products as well as the chance to participate in any
                                        future draws by Stoeger Canada.
                                    </Typography>
                                </FormControl>
                            </div>
                        </div>
                        {subscribed && (
                            <Alert severity="success" style={{ margin: theme.spacing(2, 0) }}>
                                Sign up successful! Sign up another email. | Inscription réussie! Inscrivez un autre
                                courriel.
                            </Alert>
                        )}
                        <div align="center">
                            <Button
                                sx={{ mt: 1, mx: "auto", mb: 0 }}
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Subscribe / Inscrire
                            </Button>
                        </div>
                    </form>

                    <LogoGroup />
                </StyledCard>

                <Footer />
            </StyledGrid>
        </div>
    );
}
