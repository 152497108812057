import React, { useState } from "react";
import { Button, Card, Link as MLink, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Terms from "../components/Terms";
import LogoGroup from "../components/LogoGroup";

const StyledCard = styled(Card)(({ theme }) => ({
    background: "rgba(255, 255, 255, 0.85)",
    width: "80%",
    margin: theme.spacing(8, "auto", 8),
    padding: theme.spacing(2, 6),
    [theme.breakpoints.only("sm")]: {
        width: "95%",
    },
    [theme.breakpoints.only("xs")]: {
        width: "100%",
    },
}));

export default function Home() {
    const theme = useTheme();
    const [terms, setTerms] = useState(false);

    return (
        <div>
            <Header />
            <div
                style={{
                    backgroundImage: "url(/images/background.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: theme.palette.grey[50],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    minHeight: "95vh",
                    justifyContent: "center",
                    padding: theme.spacing(3, 0, 6),
                }}
            >
                <StyledCard style={{ marginBottom: theme.spacing(2) }}>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: theme.spacing(1) }}>
                        <span>
                            <img
                                style={{ width: "200px", margin: "20px 30px 20px" }}
                                src="/images/tikka_horizontal_black.png"
                                alt="Beretta"
                            />
                        </span>
                        <span>
                            <img
                                src="/images/Burris_Logo_Icon_RedBlackWhite_CMYKb.png"
                                alt="Burris"
                                style={{ width: "200px", margin: "20px 30px 20px" }}
                            />
                        </span>
                    </div>

                    <div style={{ display: "flex", margin: "20px 0 40px" }}>
                        <img
                            src="/images/prize.png"
                            alt="Tikka T1x UPR 22LR and Burris RT-25 5-25X56MM"
                            style={{ width: "80%", margin: "auto" }}
                        />
                    </div>
                    <Typography variant="h2" align="center" style={{ marginBottom: theme.spacing(5) }}>
                        SHOOT TO WIN
                    </Typography>
                    {/* <Typography
                        variant="h6"
                        align="center"
                        style={{ fontSize: "25px", fontWeight: 750, marginBottom: theme.spacing(3) }}
                    >
                        Prize: Tikka T1x UPR 22LR and Burris RT-25 5-25X56MM
                    </Typography> */}
                    <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                        Tikka has partnered up with the CRPS and Silvercore for a Contest!
                    </Typography>
                    <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }} gutterBottom>
                        Chance to win a Tikka T1x UPR 22LR and Burris RT-25 5-25X56MM fully mounted.
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        style={{ fontSize: "25px", marginBottom: theme.spacing(5) }}
                        gutterBottom
                    >
                        Assembly valued at $2,805!
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        style={{ fontSize: "25px", marginBottom: theme.spacing(2) }}
                        gutterBottom
                    >
                        To enter, individuals must participate in a CRPS or Silvercore Outdoors match, training or range
                        day.
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        align="center"
                        style={{ fontSize: "25px", marginBottom: theme.spacing(3) }}
                    >
                        One entry per purchase and/or email. Open to legal residents of Canada and have a valid P.A.L.
                        Must be 18 years or older to participate. Please read the{" "}
                        <MLink
                            onClick={() => setTerms(true)}
                            style={{ color: theme.palette.secondary.main, textDecoration: "underline" }}
                        >
                            Terms and Conditions
                        </MLink>
                        .
                    </Typography>
                    <div align="center" component={Button} style={{ margin: theme.spacing(5, "auto", 6) }}>
                        <Button variant="contained" color="secondary" component={Link} to="/registration">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ fontSize: "18px" }}>Register Now</div>
                            </div>
                        </Button>
                    </div>
                    <LogoGroup />
                    {/* <div
                        align="center"
                        style={{
                            margin: theme.spacing(2, "auto"),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <span>
                            <a href="http://www.tikkacanada.ca" target="__blank">
                                <img src="/images/tikka_horizontal_black.png" alt="Tikka" style={{ width: "200px" }} />
                            </a>
                        </span>
                        <span>
                            <img
                                src="/images/Burris_Logo_Icon_RedBlackWhite_CMYKb.png"
                                alt="Burris"
                                style={{ width: "200px", margin: "20px 30px 20px" }}
                            />
                        </span>
                        <span>
                            <a href="https://rimfireprecision.ca/" target="__blank">
                                <img
                                    src="/images/crps.png"
                                    alt="canadian rimfire percision series"
                                    style={{ width: "200px" }}
                                />
                            </a>
                        </span>
                        <span>
                            <a href="https://silvercore.ca/" target="__blank">
                                <img
                                    src="/images/Silvercore_Badge_Dark.png"
                                    style={{
                                        width: "100px",
                                        margin: "20px 30px 20px",
                                    }}
                                    alt="silvercore"
                                />
                            </a>
                        </span>
                    </div> */}
                    <Footer />
                </StyledCard>
            </div>

            <Terms openDialog={terms} close={(c) => setTerms(false)} lang="en" />
        </div>
    );
}
