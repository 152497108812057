import React, { useEffect, useState } from "react";
import {
    Alert,
    // Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    Link,
    ListSubheader,
    MenuItem,
    Paper,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import "date-fns";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "@emotion/styled";
import Terms from "../components/Terms";
import LogoGroup from "../components/LogoGroup";

const StyledCard = styled(Paper)(({ theme }) => ({
    background: "rgba(255, 255, 255, 0.9)",
    width: "80%",
    margin: theme.spacing(8, "auto", 8),
    padding: theme.spacing(2, 3, 6),
    [theme.breakpoints.only("sm")]: {
        width: "95%",
    },
    [theme.breakpoints.only("xs")]: {
        width: "100%",
    },
}));

export default function Registration() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [terms, setTerms] = useState(false);

    const [token, setToken] = useState("");
    const [poplulateForm, setPopulateForm] = useState({});
    const [error, setError] = useState({
        isExist: false,
        type: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);
    // const [affiliationIsLoading, setAffiliationIsLoading] = useState(true);
    // const [affiliationOptions, setAffiliationOptions] = useState(false);

    const [form, setForm] = useState({
        first: "",
        last: "",
        email: "",
        province: "",
        telephone: "",
        affiliation: "",
        crps_newsletter: false,
        silvercore_newsletter: false,
        newsletter: false,
        terms: false,
    });

    function handleFormData(e) {
        console.log(e.target.value);
        if (
            e.target.name === "crps_newsletter" ||
            e.target.name === "silvercore_newsletter" ||
            e.target.name === "newsletter" ||
            e.target.name === "terms"
        ) {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            // checking telephone field and only accepting numbers
            if (e.target.name === "telephone") {
                e.target.value.match("^[0-9]*$") &&
                    setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                    });
            } else {
                setForm({
                    ...form,
                    [e.target.name]: e.target.value,
                });
            }
        }
    }

    function hanldeSubmit(e) {
        e.preventDefault();
        setLoading(true);

        setError({
            isExist: false,
            type: "",
            message: "",
        });
        const tmp = steralizeInput(form);

        // const data = new FormData();
        // data.append("first", tmp.first);
        // data.append("last", tmp.last);
        // data.append("email", tmp.email);
        // data.append("province", tmp.province);
        // data.append("telephone", tmp.telephone);
        // data.append("affiliation", tmp.affiliation);
        // data.append("crps_newsletter", tmp.crps_newsletter);
        // data.append("newsletter", tmp.newsletter);
        // data.append("terms", tmp.terms);
        // data.append("token", token);

        // console.log(data.get("files"));
        // fetch("http://localhost:8080/api/enterEntry", {
        fetch("https://secondtonone.tikkacanada.ca/api/enterEntry", {
            method: "post",

            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "multipart/form-data",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            // body: data,
            body: JSON.stringify({ ...tmp, token: token }),
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.status === 200) {
                    navigate("/thankyou");
                }
                return res.json();
            })
            .then((data) => {
                console.log("Data: ", data);
                if (data.error) {
                    handleError(data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }

    // function testing() {
    //     // console.log("here");
    //     fetch("http://localhost:8080/api/testing", {
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         method: "post",
    //         body: JSON.stringify({
    //             token: token,
    //         }),
    //     })
    //         .then((res) => {
    //             return res.json();
    //         })
    //         .then((data) => {
    //             // console.log(data);
    //             setToken(data.access_token);
    //         })
    //         .catch((err) => console.log(err));
    // }

    useEffect(() => {
        // fetch("http://localhost:8080/api/getAccessToken", {
        fetch("https://crps-promo.herokuapp.com/api/getAccessToken", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setToken(data.access_token);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (token !== "") {
            // testing();
            // fetch("http://localhost:8080/api/popluateForm", {
            fetch("https://secondtonone.tikkacanada.ca/api/popluateForm", {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                body: JSON.stringify({
                    token: token,
                    fields: ["Province__c", "Model__c", "Province_Purchased__c", "Question_1__c", "Question_2__c"],
                }),
            })
                .then((res) => {
                    // console.log(res);
                    return res.json();
                })
                .then((data) => {
                    // console.log(data);
                    setPopulateForm(data);
                    // setAffiliationOptions(data.Affiliation__c.map((m) => ({ title: m.label, value: m.value })));

                    // setAffiliationIsLoading(false);
                })
                .catch((err) => console.log(err));
        }
    }, [token]);

    // console.log(form);
    //=============HELPER FUNCTIONS==================
    const steralizeInput = (form) => {
        let tmp = Object.entries(form);
        tmp = tmp.map((m) => (typeof m[1] === "string" ? [m[0], m[1].replace(/^\s+|\s+$/gm, "")] : m));

        return Object.fromEntries(tmp);
    };

    const handleError = (errMessage) => {
        if (errMessage.errorCode === "DUPLICATE_VALUE") {
            setError({
                isExist: true,
                type: "Duplicate Entry: ",
                message: "An Entry for this email has already been submitted.",
            });
        } else if (errMessage.errorCode === "FIELD_CUSTOM_VALIDATION_EXCEPTION") {
            setError({
                isExist: true,
                type: "Form Error: ",
                message: errMessage.message + ".",
            });
        } else {
            setError({
                isExist: true,
                type: "Other Error: ",
                message: errMessage + ".",
            });
        }
    };

    // const handleAutoCompleteData = (e, nv) => {
    //     console.log(nv);
    //     if (nv === null || nv === undefined || nv === "") {
    //         setForm({ ...form, [e.target.id]: "" });
    //     }
    //     // else {
    //     setForm({ ...form, [e.target.id]: nv.value });
    //     // }
    // };
    return (
        <div>
            <Header />

            <div
                style={{
                    backgroundImage: "url(/images/background.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: theme.palette.grey[50],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    minHeight: "100vh",
                    justifyContent: "center",
                    padding: theme.spacing(3, 0),
                }}
            >
                <StyledCard>
                    <Typography variant="h5" style={{ textTransform: "uppercase" }}>
                        Entry Registration:
                    </Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ textTransform: "uppercase" }}
                        gutterBottom
                    >
                        One entry per purchase and/or email.
                    </Typography>
                    <form onSubmit={hanldeSubmit}>
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    variant="outlined"
                                    style={{ margin: theme.spacing(1, 0) }}
                                    label="First Name / Prénom"
                                    fullWidth
                                    required={true}
                                    name="first"
                                    value={form.first}
                                    onChange={handleFormData}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    variant="outlined"
                                    style={{ margin: theme.spacing(1, 0) }}
                                    label="Last Name / Nom de famille"
                                    fullWidth
                                    required={true}
                                    name="last"
                                    value={form.last}
                                    onChange={handleFormData}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            variant="outlined"
                            style={{ margin: theme.spacing(1, 0) }}
                            fullWidth
                            label="Email / Courriel"
                            required={true}
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleFormData}
                        />
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    style={{ margin: theme.spacing(1, 0) }}
                                    required={true}
                                    fullWidth
                                    name="province"
                                    value={form.province}
                                    onChange={handleFormData}
                                    variant="outlined"
                                    label="Province / Province"
                                    select
                                >
                                    {poplulateForm.Province__c !== undefined ? (
                                        poplulateForm.Province__c.map((m) => (
                                            <MenuItem key={m.value} value={m.value}>
                                                {m.label}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <ListSubheader>Loading...</ListSubheader>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    variant="outlined"
                                    style={{ margin: theme.spacing(1, 0) }}
                                    pattern="[0-9]*"
                                    fullWidth
                                    type="text"
                                    label="Telephone Number / Numéro de Téléphone"
                                    name="telephone"
                                    value={form.telephone}
                                    onChange={handleFormData}
                                />
                            </Grid>
                        </Grid>
                        {/* <Autocomplete
                            disablePortal
                            id="affiliation"
                            options={affiliationOptions}
                            loading={affiliationIsLoading}
                            sx={{ my: 1 }}
                            value={form.affiliation || ""}
                            onChange={handleAutoCompleteData}
                            getOptionSelected={(option, value) => option.title === value}
                            // getOptionSelected={(option, value) => option.title === value}
                            OptionEqualToValue={(option, value) => option.value === ""}
                            getOptionLabel={(option) => option.title || ""}
                            // defaultValue={""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="With whom are you affiliated? Canadian Rimfire Precision Series (CRPS) or Silvercore?"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        /> */}
                        <TextField
                            style={{ margin: theme.spacing(1, 0) }}
                            required={true}
                            fullWidth
                            value={form.affiliation}
                            onChange={handleFormData}
                            variant="outlined"
                            name="affiliation"
                            label="With whom are you affiliated? Canadian Rimfire Precision Series (CRPS) or Silvercore Outdoors?"
                            select
                        >
                            {poplulateForm.Affiliation__c !== undefined ? (
                                poplulateForm.Affiliation__c.map((m) => (
                                    <MenuItem key={m.value} value={m.value}>
                                        {m.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <ListSubheader>Loading...</ListSubheader>
                            )}
                        </TextField>
                        <div style={{ margin: theme.spacing(1.5, "4px", 1) }}>
                            <FormControl>
                                <Typography variant="body2" style={{ display: "inline" }}>
                                    <Checkbox
                                        checked={form.newsletter}
                                        size="small"
                                        onChange={handleFormData}
                                        name="newsletter"
                                        color="secondary"
                                        style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                    />
                                    <strong>
                                        Please subscribe me to Stoeger Canada, the Canadian distributor for Tikka and
                                        Tikka's Newsletter.
                                    </strong>{" "}
                                    By checking this box, I opt-in to receive periodic information on promotions, sales,
                                    events and new products as well as the chance to participate in any future draws by
                                    Stoeger Canada.
                                </Typography>
                            </FormControl>
                        </div>
                        <div style={{ margin: theme.spacing(1, "4px") }}>
                            <FormControl required>
                                <Typography variant="body2" style={{ display: "inline" }}>
                                    <Checkbox
                                        checked={form.crps_newsletter}
                                        size="small"
                                        onChange={handleFormData}
                                        name="crps_newsletter"
                                        color="secondary"
                                        style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                    />
                                    <strong>
                                        Please subscribe me to the Canadian Rimfire Precision Series (CRPS) Newsletter.
                                    </strong>{" "}
                                    By checking this box, I opt-in to receive periodic information from CRPS.
                                </Typography>
                            </FormControl>
                        </div>
                        <div style={{ margin: theme.spacing(1, "4px") }}>
                            <FormControl required>
                                <Typography variant="body2" style={{ display: "inline" }}>
                                    <Checkbox
                                        checked={form.silvercore_newsletter}
                                        size="small"
                                        onChange={handleFormData}
                                        name="silvercore_newsletter"
                                        color="secondary"
                                        style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                    />
                                    <strong>Please subscribe me to the Silvercore Outdoors Newsletter.</strong> By
                                    checking this box, I opt-in to receive periodic information from Silvercore
                                    Outdoors.
                                </Typography>
                            </FormControl>
                        </div>

                        <div style={{ margin: theme.spacing(4, "4px") }}>
                            <FormControl required>
                                <Typography variant="body2" style={{ display: "inline" }}>
                                    <Checkbox
                                        required={true}
                                        checked={form.terms}
                                        size="small"
                                        onChange={handleFormData}
                                        name="terms"
                                        color="secondary"
                                        style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                    />
                                    Accept{" "}
                                    <Link
                                        onClick={() => setTerms(true)}
                                        style={{
                                            color: theme.palette.secondary.main,
                                            textTransform: "none",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        <i>Terms & Conditions</i>
                                    </Link>
                                </Typography>
                            </FormControl>
                        </div>
                        {error.isExist && (
                            <Alert
                                severity="error"
                                style={{
                                    border: "2px solid #f44336",
                                    margin: theme.spacing(4, 0, 2),
                                }}
                            >
                                {error.type}
                                {error.message}
                            </Alert>
                        )}
                        <div align="center">
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ margin: "15px 0 0", textTransform: "uppercase", fontWeight: "bold" }}
                            >
                                One entry per purchase and/or email
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: theme.spacing(0, "auto", 0), width: "20%" }}
                                type="submit"
                                disabled={loading === true}
                            >
                                Submit
                            </Button>
                            <div style={{ marginTop: "16px" }}>{loading && <CircularProgress />}</div>
                        </div>
                    </form>
                    <LogoGroup />
                    <Footer />
                </StyledCard>
            </div>
            <Terms openDialog={terms} close={(c) => setTerms(false)} lang="en" />
        </div>
    );
}
