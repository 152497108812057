import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Registration from "./pages/Registration";
import Error404 from "./pages/404";
import ThankYou from "./pages/Thankyou";

export default function AppRouter() {
    return (
        <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/registration" element={<Registration />} exact />
            <Route path="/thankyou" element={<ThankYou />} exact />
            <Route path="*" element={<Error404 />} exact />
        </Routes>
    );
}
